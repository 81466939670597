import axios from "axios";
import qs from "qs";
import UrlUtils from "./url";
import DateUtils from "./date";

const mapPrices = product => ({
  ...product,
  prices: product.prices || { min_price: product.price, max_price: null }
});

export class RequestGroup {
  constructor(cancellable = true) {
    if (cancellable) {
      this.setCancelToken();
    }
  }

  setCancelToken = () => {
    this.cancelTokenSource = axios.CancelToken.source();
    this.cancelToken = this.cancelTokenSource.token;
  };

  cancelAndReuse = message => {
    /**
     * This Axios cancel function is implemented using Node.js version 14.
     * However, after upgrading to Node.js version 18.17.0 (the LTS as of Jul 26, 2023),
     * it encountered an error due to breaking changes introduced in version 18.17.0.
     * The error resulted in an "Uncaught runtime error."
     * Additionally, as the launch of the new dashboard relies on the old one,
     * a decision was made to maintain the current implementation temporarily
     * until adequate time is allocated to address the underlying issue.
     */
    console.log("cancelAndReuse", message);
    // this.cancelTokenSource.cancel("cancelAndReuse");
    // this.setCancelToken();
  };

  getListOrganizations = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/admin/list_organizations"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getOrgDomains = async (orgId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/admin/organization/domains"),
      {
        cancelToken: this.cancelToken,
        params: { org_id: orgId },
      }
    );
    return data;
  };

  deleteOrgDomain = async (id, orgId) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/admin/organization/domain"),
      {
        cancelToken: this.cancelToken,
        params: { id, org_id: orgId },
      }
    );
    return data;
  };

  getOrgDataByPName = async orgName => {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/organization", true) + "?p_name=" + orgName,
      {
        cancelToken: this.cancelToken
      }
    );
    return response.data;
  };

  getOrgDataById = async orgId => {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/organization", true) + "?org_id=" + orgId,
      {
        cancelToken: this.cancelToken
      }
    );
    return response.data;
  };

  getBrands = async orgId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/brands"),
      {
        params: { org_id: orgId },
        cancelToken: this.cancelToken
      }
    );
    return data.content || data;
  };

  getOrgThemes = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/themes"),
      {
        cancelToken: this.cancelToken
      }
    );
    return data;
  };

  getCategories = async orgId => {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/categories", true)
      + (orgId ? `?org_id=${Number(orgId)}` : ""),
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  getTags = async (orgId, categoryName) => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/tags", true), {
      params: { org_id: orgId, category_name: categoryName },
      cancelToken: this.cancelToken,
    });
    return data.content.map(tag => {
      let metadata;
      try {
        metadata = JSON.parse(tag.metadata) || {};
      } catch {
        metadata = {};
      }
      return {
        ...tag,
        metadata,
      };
    });
  };

  getTag = async tagId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/tag", true), {
      params: { tag_id: tagId },
      cancelToken: this.cancelToken
    });
    try {
      data.metadata = JSON.parse(data.metadata);
    // eslint-disable-next-line no-empty
    } catch {}
    return data;
  };

  getThemesClasses = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/admin/themes/class"),
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  deleteThemesClass = async (id) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/admin/themes/class"),
      { params: { id }, cancelToken: this.cancelToken }
    );
    return data;
  };

  getThemes = async (classId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/admin/themes"),
      { cancelToken: this.cancelToken, params: { class_id: classId } }
    );
    return data;
  };

  deleteTheme = async (uid) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/admin/themes"),
      { cancelToken: this.cancelToken, params: { id: uid } }
    );
    return data;
  };

  getOrganizationThemesClasses = async (orgId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/themes/class"),
      { cancelToken: this.cancelToken, params: { org_id: orgId } }
    );
    return data;
  };

  getTagsTree = async orgId => {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/tagstree", true)
      + (orgId ? `?org_id=${Number(orgId)}` : ""),
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  deleteTag = async tagId => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/tag")
        + `?tag_id=${Number(tagId)}`,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  postProductsTags = async (productsIds, tagsIds) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/product/tag"),
      { products_ids: productsIds, tags_ids: tagsIds },
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  deleteProductsTags = async (productsIds, tagsIds) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/product/tag"),
      {
        params: { products_ids: productsIds, tags_ids: tagsIds },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteProductImage = async (imageId) => {
    const { data } = await axios.delete(UrlUtils.getProperApiUrl("/product/image"), {
      params: { image_id: imageId },
      cancelToken: this.cancelToken
    });
    return data;
  };

  getBrand = async brandId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/brand", true)
        + `?brand_id=${Number(brandId)}`,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  deleteBrand = async brandId => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/brand")
        + `?brand_id=${Number(brandId)}`,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  getCountries = async (orgId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/countries", true),
      { params: { org_id: orgId }, cancelToken: this.cancelToken }
    );
    return data;
  };

  getSubAreas = async ({ countryId, cityId, areaId } = {}) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/sub_areas"),
      {
        params: { country_id: countryId, city_id: cityId, area_id: areaId },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  postSubAreas = async (subAreas) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/organization/sub_areas"),
      { sub_areas: subAreas },
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteSubAreas = async (subAreas) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/sub_areas"),
      {
        params: {
          sub_areas: subAreas,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getShop = async shopId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/shop", true)
        + `?shop_id=${Number(shopId)}`,
      { cancelToken: this.cancelToken }
    );
    return data.content || data;
  };

  getShops = async (start = 0, count = 100) => {
    const response = await axios.get(
      UrlUtils.getProperApiUrl("/organization/shops"),
      { params: { start, count }, cancelToken: this.cancelToken }
    );
    return response.data;
  };

  getProductsFeaturesTypes = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/products_features/types"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getProducts = async (filters) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/products", true),
      {
        params: filters,
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    return { ...data, products: data.products.map(product => mapPrices(product)) };
  };

  getEmptyProducts = async orgId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/product/empty_products"),
      {
        params: { org_id: orgId },
        cancelToken: this.cancelToken,
      }
    );
    return Array.isArray(data) ? { total: data.length, products: data } : data;
  };

  getFilters = async (filters) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/filters", true),
      {
        params: filters,
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    return data;
  };

  getProduct = async productId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/product"), {
      params: { product_id: productId },
      cancelToken: this.cancelToken
    });
    return { ...data, variants: data.variants || [], images: data.images || [] };
  };

  getUsersCarts = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/users/carts"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getRelatedProducts = async (productId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/related_products", true),
      {
        params: { product_id: productId },
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    const { products, total } = Array.isArray(data) ? { products: data, total: data.length } : data;
    return { products: products.map(product => mapPrices(product)), total };
  };

  postRelatedProducts = async (add, productId, relatedProductsIds) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/product/related_products"),
      {
        add,
        product_id: productId,
        related_products_ids: relatedProductsIds,
      },
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  getVariants = async (filters) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/variants", true),
      {
        params: filters,
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    return data;
  };

  getEmptyCollections = async orgId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/product/empty_collections"),
      {
        params: { org_id: orgId },
        cancelToken: this.cancelToken,
      }
    );
    return Array.isArray(data) ? { total: data.length, products: data.map(product => mapPrices(product)) } : data;
  };

  getCollection = async productId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/collection", true), {
      params: { id: productId },
      cancelToken: this.cancelToken
    });
    return { ...data, variants: data.variants || [], images: data.images || [] };
  };

  deleteCollection = async collectionId => {
    const { data } = await axios.delete(UrlUtils.getProperApiUrl("/product/collection"), {
      params: { id: collectionId }
    });
    return data;
  };

  postCollectionElement = async (operation, productId, variantIds) => {
    const { data } = await axios.post(UrlUtils.getProperApiUrl("/product/collection/element"), {
      operation,
      product_id: productId,
      variant_ids: variantIds
    }, { cancelToken: this.cancelToken });
    return data;
  };

  deleteCollection = async (collectionIds) => {
    const { data } = await axios.delete(UrlUtils.getProperApiUrl("/product/collection"), {
      params: { id: collectionIds },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    });
    return data;
  };

  deleteProduct = async (productIds, forceDeleteCollectionItems) => {
    const { data } = await axios.delete(UrlUtils.getProperApiUrl("/product"), {
      params: {
        product_id: productIds,
        force_delete_collection_items: forceDeleteCollectionItems,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    });
    return data;
  };

  hideProduct = async (productId, hide = true) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/product/hide"),
      null,
      {
        params: { product_id: productId, hide },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  get360Products = async (shopId, name) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/360view/products"),
      {
        params: { shop_id: shopId, name },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: false }),
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  set360Search = async (include, productIds) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/product/set_360_search"),
      null,
      { params: { include, product_id: productIds }, cancelToken: this.cancelToken }
    );
    return data;
  };

  getOrderList = async query => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/order/list"),
      {
        params: query,
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    return data;
  };

  getOrderInfo = async orderId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/order/info"), {
      params: { order_id: orderId },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  getMetaOrderInfo = async metaOrderId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/order/meta_order/info"), {
      params: { id: metaOrderId },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  postOrderStatusUpdate = async (orderId, status) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/order/status/update"),
      {
        order_id: orderId,
        status
      },
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  postOrderConfirm = async orderId => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/order/confirm"),
      null,
      {
        params: { order_id: orderId },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getReturnRequests = async ({
    metaOrderId,
    shopId,
    status,
    start,
    count,
    dateFrom,
    dateTo
  } = {}) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/order/return/requests"),
      {
        params: {
          meta_order_id: metaOrderId,
          shop_id: shopId,
          status,
          start,
          count,
          date_from: dateFrom ? DateUtils.toISOStringWithoutMillis(dateFrom) : null,
          date_to: dateTo ? DateUtils.toISOStringWithoutMillis(dateTo) : null
        },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getReturnRequest = async returnRequestId => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/order/return/request"), {
      params: { id: returnRequestId },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  postConfirmReturnRequest = async returnRequestId => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/order/return/confirm"),
      null,
      {
        params: { id: returnRequestId },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  postReturnRequestReceive = async receivedItemsData => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/order/return/received_item"),
      receivedItemsData,
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getUserList = async (role, orgId, shopId) => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/user/list"), {
      params: { role, org_id: orgId, shop_id: shopId },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  getCustomerList = async (paging_start = 0, paging_count = 10000) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/user/list/customer"),
      {
        params: { paging_start, paging_count },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getUserInfo = async (userId, employee = true) => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/user/info"), {
      params: { id: userId, is_employee: employee },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  postUSerSuspend = async (userId, employee, suspend) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/user/suspend"),
      null,
      {
        params: { user_id: userId, is_employee: employee, suspend },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getProductListCsvTemplate = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/upload/productlist/csv/template"),
      { cancelToken: this.cancelToken, responseType: "blob" }
    );
    return data;
  };

  getProductListExcelTemplate = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/upload/productlist/xls/template"),
      { cancelToken: this.cancelToken, responseType: "blob" }
    );
    return data;
  };

  getExportProductsCsv = async (shopId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/export/products/csv"),
      { params: { shop_id: shopId }, cancelToken: this.cancelToken, responseType: "blob" }
    );
    return data;
  };

  getExportProductsExcel = async (shopId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/export/products/xlsx"),
      { params: { shop_id: shopId }, cancelToken: this.cancelToken, responseType: "blob" }
    );
    return data;
  };

  getProductFeatures = async (orgId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/products_features"),
      {
        params: { organization_id: orgId },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteProductFeature = async (id) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/products_feature"),
      {
        params: { id },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getImageBulkTemplate = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/product/image/bulk/template"),
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  getOrgExtraAttributes = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/extra_attribute"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  get360Sections = async shopId => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl(`/360view/sections?shop_id=${shopId}`),
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  post360Sections = async (shopId, floors) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl(`/360view/sections?shop_id=${shopId}`),
      floors,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  publish360 = async shopId => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl(`/360view/publish?shop_id=${shopId}`),
      null,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  postFile = async (file, orgId, cancelToken) => {
    const formData = new FormData();
    formData.append("file", file);
    if (orgId) formData.append("org_id", orgId);
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/files"),
      formData,
      {
        cancelToken: this.cancelToken || cancelToken,
      }
    );
    return data;
  };

  deleteFile = async (fileUrl) => {
    const { data } = axios.delete(UrlUtils.getProperApiUrl("/files"), {
      params: { file_name: fileUrl },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  postOrgImage = async (file, properties, onUploadProgress, cancelToken) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("properties", JSON.stringify(properties));
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/organization/image"),
      formData,
      { cancelToken: this.cancelToken || cancelToken, onUploadProgress }
    );
    return data;
  };

  deleteOrganizationImage = async (imageId = null, imageUrl) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/image"),
      {
        params: { image_id: imageId, url: imageUrl },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  post360viewShop = async shopId => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/360view/shops"),
      { shop_id: shopId },
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  // in case of conflict with #61
  // accept this as the api would be changed from /360view/sections to /360view/floors
  delete360Floors = async shopId => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/360view/floors") + `?shop_id=${shopId}`,
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  delete360Floor = async (shopId, floorId, productsPositionsConfirm = false) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/360view/floor"),
      {
        params: {
          shop_id: shopId,
          floor_id: floorId,
          products_positions_confirm: productsPositionsConfirm,
        },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  delete360Section = async (shopId, sectionId, productsPositionsConfirm = false) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/360view/section"),
      {
        params: {
          shop_id: shopId,
          section_id: sectionId,
          products_positions_confirm: productsPositionsConfirm,
        },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  delete360Scene = async (shopId, SceneId, productsPositionsConfirm = false) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/360view/scene"),
      {
        params: {
          shop_id: shopId,
          scene_id: SceneId,
          products_positions_confirm: productsPositionsConfirm,
        },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getPromotions = async ({ status, startDate, endDate, id, start, count } = {}) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/promotions"),
      {
        params: {
          status,
          start_date: startDate
            ? DateUtils.toISOStringWithoutMillis(startDate)
            : null,
          end_date: endDate ? DateUtils.toISOStringWithoutMillis(endDate) : null,
          id,
          start,
          count,
        },
        cancelToken: this.cancelToken,
      }
    );
    let promotions = Array.isArray(data) ? data : data.promotions;
    promotions = promotions.map((promotion) => ({
      ...promotion,
      name: promotion.name || promotion.identifier,
    }));
    return { ...data, promotions };
  };

  postPromotion = async (promotion) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/organization/promotion"),
      promotion,
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deletePromotion = async (promoId) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/promotion"),
      {
        params: { id: promoId },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getProductReview = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("product/review"),
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  postProductReviewApprove = async (id) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/product/review/approve"),
      null,
      { params: { id }, cancelToken: this.cancelToken }
    );
    return data;
  };

  getSubscribedUsers = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/subscribed_users"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteSubscribedUser = async (email) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/organization/subscribed_users"),
      {
        params: { email },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getOrganizationSiteMap = async (
    url,
    includeProducts,
    includeCollections,
    includeBrands,
    includeTags,
    includeTagsTree
  ) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/organization/sitemap", true),
      {
        params: {
          url,
          include_products: includeProducts,
          include_collections: includeCollections,
          include_brands: includeBrands,
          include_tags: includeTags,
          include_tags_tree: includeTagsTree,
        },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getNavboxSeo = async (orgId, type, id) => {
    const { data } = await axios.get(UrlUtils.getProperApiUrl("/seo", true), {
      params: {
        org_id: orgId,
        type,
        id,
      },
      cancelToken: this.cancelToken,
    });
    return data;
  };

  postOrganizationSeo = async (type, id, keywords) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/organization/seo"),
      {
        type,
        id,
        keywords,
      },
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  getStatisticsCarts = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/carts"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getStatisticsSoldProducts = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/sold_products"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getStatisticsUsers = async (month) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/users"),
      {
        params: { month },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getStatisticsOrders = async (statuses, monthCount = 12, type = "TOTAL") => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/orders"),
      {
        params: { statuses, month_count: monthCount, type },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getStatisticsSales = async (month, week) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/statistics/sales"),
      {
        params: { month, week },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getCategoriesList = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/navbox/categories"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteCategory = async categoryId => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/admin/category")
      + `?category_id=${Number(categoryId)}`,
      {
        cancelToken: this.cancelToken
      }
    );
    return data;
  };

  getSessions = async (orgId) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/videochat/sessions"),
      {
        params: { org_id: orgId },
        cancelToken: this.cancelToken,
      }
    );
    return data?.sessions || data;
  };

  postJoinSession = async (orgId, sessionName) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/videochat/session"),
      null,
      {
        params: { org_id: orgId, session_name: sessionName },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  postLeaveSession = async (orgId, sessionName, endCall) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/videochat/leave"),
      null,
      {
        params: { org_id: orgId, session_name: sessionName, end_call: endCall },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteVariant = async (varianttIds, forceDeleteCollectionItems) => {
    const { data } = await axios.delete(UrlUtils.getProperApiUrl("/product/variant"), {
      params: {
        variant_id: varianttIds,
        force_delete_collection_items: forceDeleteCollectionItems,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    });
    return data;
  };

  getOrdersFilter = async query => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/order/filters")
      + UrlUtils.toSearchParams(query),
      { cancelToken: this.cancelToken }
    );
    return data;
  };

  downloadOrders = async query => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/export/orders"),
      {
        params: query,
        cancelToken: this.cancelToken,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
      }
    );
    return data;
  };

  postExport360Images = async (shopId) => {
    const { data } = await axios.post(
      UrlUtils.getProperApiUrl("/360view/export_images"),
      null,
      {
        params: { shop_id: shopId },
        responseType: "blob",
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };
  // Loyalty

  getTiersList = async () => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/loyalty/tier/list"),
      {
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  getTier = async (tier_id) => {
    const { data } = await axios.get(
      UrlUtils.getProperApiUrl("/loyalty/tier"),
      {
        params: { tier_id },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };

  deleteTier = async (tier_id) => {
    const { data } = await axios.delete(
      UrlUtils.getProperApiUrl("/loyalty/tier/delete"),
      {
        params: { tier_id },
        cancelToken: this.cancelToken,
      }
    );
    return data;
  };
}

const DefaultRequests = new RequestGroup(false);

export const getOldImagesCleaner = (imagesToCleanup, isOranizationImage = false) =>
  (oldData, newData, submittedValues = {}) => {
    imagesToCleanup.forEach(({ propertyName, fieldName }) => {
      if (
        oldData[propertyName]
        && fieldName in submittedValues
        && oldData[propertyName] !== newData[propertyName]
      ) {
        isOranizationImage
          ? DefaultRequests.deleteOrganizationImage(
            undefined,
            oldData[propertyName]
          )
          : DefaultRequests.deleteFile(oldData[propertyName]);
      }
    });
  };

export default {
  ...DefaultRequests,
};
